import type { Nullable } from '@revolutionprep/types'

interface MetaRedirect {
  url: Nullable<string>
  legacy: boolean
}

export default defineNuxtRouteMiddleware((to) => {
  // nuxt runtime config
  const config = useRuntimeConfig()

  // create the meta object from route.meta
  const meta = {
    url: to.meta.url,
    legacy: to.meta.legacy
  } as MetaRedirect

  /**
   * Returns a redirect url
   *
   * @param {MetaRedirect} { url, legacy }
   * @return {*}  {string}
   */
  const setRedirectUrl = ({ url, legacy }: MetaRedirect): string => {
    // sets socrates or legacy tutor url
    const tutorUrl: string = legacy
      ? config.public.tutorLegacyUrl
      : config.public.tutorUrl

    // if url is not set redirect to home
    if (!url && !legacy) {
      return tutorUrl
    }

    // if url is not set redirect same route in the legacy app
    if (!url && legacy) {
      return `${tutorUrl}${to.path}`
    }

    // if url includes https redirect there
    if (url?.includes('https://')) {
      return url
    } else {
      return `${tutorUrl}${url}`
    }
  }

  const redirectUrl = setRedirectUrl(meta)

  if (process.client) {
    window.location.replace(redirectUrl)
  } else {
    return navigateTo(redirectUrl)
  }
})
